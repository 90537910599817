import { render, staticRenderFns } from "./DistributorMenu.vue?vue&type=template&id=7654397b&scoped=true&"
import script from "./DistributorMenu.vue?vue&type=script&lang=js&"
export * from "./DistributorMenu.vue?vue&type=script&lang=js&"
import style0 from "./DistributorMenu.vue?vue&type=style&index=0&id=7654397b&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7654397b",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlideGroup } from 'vuetify/lib/components/VSlideGroup';
import { VSlideItem } from 'vuetify/lib/components/VSlideGroup';
installComponents(component, {VBtn,VCol,VContainer,VImg,VList,VListItem,VListItemContent,VListItemGroup,VListItemTitle,VRow,VSlideGroup,VSlideItem})
